<template>
    <div class="topline flex">
        <div class="l flex">
            <div class="leftmenu-toggler" @click="menuToggle"><i class="material-icons">menu</i></div>
            <div class="user">{{ this.$store.state.userData.name }} ({{this.$store.state.userData.roles.map(item => item.title).join(', ')}})</div>
        </div>
        <div class="btns flex-st">
            <push/>
            <router-link to="/panel/calendar"><i class="material-icons">watch_later</i></router-link>
            <router-link to="/logout"><i class="material-icons">arrow_forward</i></router-link>
        </div>
    </div>
</template>

<script>
import push from "@/components/push/index.vue";
export default {
    name: "topline",
    components: {push},
    methods: {
        menuToggle() {
            this.$store.state.navBarOpened = !this.$store.state.navBarOpened
        }
    },
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/base";
.topline {
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    height: 60px;
    padding: 18px 30px;
    @media (max-width: 900px) {
        height: auto;padding: 18px 30px 10px;
    }
    .user {
        @media (max-width: 900px) {
            margin: 0 0 8px;
        }
    }
    .btns {
        @media (max-width: 900px) {
            margin: 0 0 8px;
        }
        a {
            display: block;margin: 0 0 0 20px;
        }
    }
    .l {
        align-items: center;
        height: 24px;line-height: 24px;
        .leftmenu-toggler {
            cursor: pointer;
            margin: 0 20px 0 0;
            height: 24px;
            @media (max-width: 900px) {
                display: none;
            }
        }
    }
}
</style>